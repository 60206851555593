import React, { useState, useEffect } from 'react';
import QuizMcq from "./QuizMcq"; // Import the QuizMcq component
import dahi from "./img/dahi.png";
import jamun from "./img/jamun.png";
import mango from "./img/mango.png";
import shev from "./img/shev.png";
import logo from "./img/logo.png";
import home_button from "./img/home_button.png";
import welcome_text from "./img/welcome_text.png";
import './css/home.css';

const Quiz = () => {
  const [fadeIn, setFadeIn] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false); // State to control the display of QuizMcq component
  const [fadeOut, setFadeOut] = useState(false); // State to control fade-out animation

  useEffect(() => {
    if (fadeOut) {
      const timeout = setTimeout(() => {
        setFadeOut(false);
        setShowQuiz(true);
      }, 300); // Delay of 0.5 seconds

      return () => clearTimeout(timeout);
    }
  }, [fadeOut]);

  const handleButtonClick = () => {
    setFadeIn(false); // Start fade-out animation
    setTimeout(() => setFadeOut(true), 500); // Start showQuiz after fade-out animation
  };

  return (
    <div className='quiz-container'>
      {showQuiz ? (
        <QuizMcq />
      ) : (
        <div className={`first-page ${fadeIn ? 'fade-in' : 'fade-out'}`}>
          <div className="content">
            <div className="text-center">
              <img src={dahi} alt="Dish 1" className="dish dahi" />
              <img src={jamun} alt="Dish 2" className="dish jamun" />
              <img src={mango} alt="Dish 3" className="dish mango" />
              <img src={shev} alt="Dish 4" className="dish shev" />
            </div>
          </div>
          <div className="text-center">
            <img src={logo} alt="Haldiram's Logo" className="haldiram_logo" />
            <h1 className="mt-5 welcome-text">
              <span className="blue_text">
                <img src={welcome_text} alt="Welcome Text" className={`image-container ${fadeIn ? 'fade-in' : 'fade-out'}`} />
              </span>
            </h1>
          </div>
          <div className="text-center button-section">
            <button onClick={handleButtonClick} className="btn-custom">
              <img src={home_button} alt="Home Button" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Quiz;
