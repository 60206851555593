import React from "react";
import "./css/home.css";
import Quiz from "./Quiz";
function Home() {
  return (
    <div fluid className="home-page  ">
      <div className="header">
        <Quiz />
      </div>
    </div>
  );
}

export default Home;
