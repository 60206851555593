import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home'
const App = () => {
  const [theme, colorMode] = useMode();
  return (
    <>
      <Navbar/>
      <Home/>
      <Footer/>
    </>
  );
};

export default App;
