import React, { useState, useEffect } from "react";
import "./css/quizmcq.css";
import submit_question from './img/submit_question.png'
import logo from "./img/logo.png";
import q1 from './img/q1.png';
import q2 from './img/q2.png';
import q3 from './img/q3.png';
import q4 from './img/q4.png';
import q5 from './img/q5.png';
import loading from './img/loading.gif';
import previous from './img/next.png'
import jethalal from './img/jethalal.gif';
import jethatext from './img/jethatext.png';
import scoretext from './img/scoretext.png';

const questions = [
  {
    question: q1,
    options: ["a. Aloo Bhujia", "b. Bhujia Sev", "c. Both a and b", "d. All of the above"],
  },
  {
    question: q2,
    options: [
      "a. Grab it as fast as you can",
      "b. Pretend to hesitate first",
      "c. Graciously accept the gesture",
      "d. Say thank you a million times",
    ],
  },
  {
    question: q3,
    options: [
      "a. At breakfast",
      "b. Evening snack",
      "c. Midnight snack",
      "d. 1pm, 2pm, 3pm, 4pm…",
    ],
  },
  {
    question: q4,
    options: [
      "a. 2-4",
      "b. Don't know your full potential yet",
      "c. Unlimited",
      "d. Till someone snatches it up from you",
    ],
  },
  {
    question: q5,
    options: [
      "a. Stare at them till they give it to you",
      "b. Walk away because you will be tempted",
      "c. Snatch the packet and run away",
      "d. Do nothing",
    ],
  },
];

const QuizMcq = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill([]));
  const [showResult, setShowResult] = useState(false);
  const [submitted, setSubmitted] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [showLastImg, setShowLastImg] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLastImg(true);
    }, 500); // 500 milliseconds = 0.5 seconds

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  useEffect(() => {
    if (showResult) {
      setShowImage(true);
      setTimeout(() => {
        setShowImage(false);
      }, 5000);
    }
  }, [showResult]);

  const handleCheckboxChange = (option) => {
    const updatedAnswers = [...answers];
    if (!updatedAnswers[currentQuestion].includes(option)) {
      updatedAnswers[currentQuestion].push(option); // Add option if not already selected
    } else {
      updatedAnswers[currentQuestion] = updatedAnswers[currentQuestion].filter((item) => item !== option); // Remove option if already selected
    }
    setAnswers(updatedAnswers);
    setSubmitted(false);
  };

  const handleSubmit = () => {
    if (answers[currentQuestion].length > 0) {
      setSubmitted(true); // Reset submitted state after submitting the answer
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
      } else {
        setShowResult(true);
      }
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const renderPreviousImage = () => {
    // Apply justify-content: center style to the logo container when showImage and showLastImg are false
    const logoStyle = (!showImage && !showLastImg) ? { justifyContent: 'center' } : {};
  
    return (
      <div className="logo" style={logoStyle} id="logo">
        {/* Always render the logo */}
        <img src={logo} alt="Haldiram's Logo" className="haldiram_logo" />
        {/* Hide previous image for the first question */}
        {currentQuestion !== 0 && !showResult && (
          <img className="previous" src={previous} alt="previous" onClick={handlePreviousQuestion} />
        )}
      </div>
    );
  };
  
  
  return (
    <div className="app1">
      <div className="logo" >
        
        {renderPreviousImage()}
      </div>
      {!showResult ? (
        <div className="quizMcq-container">
          <div className="question">
            <img src={questions[currentQuestion].question} alt="Question" />
          </div>
          <div className="options">
            {questions[currentQuestion].options.map((option, index) => (
              <label key={index}>
                {option}
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(option)}
                  checked={answers[currentQuestion].includes(option)}
                />
              </label>
            ))}
          </div>
          <button className="submitbutton" onClick={handleSubmit} disabled={submitted}>
            <img className="submitquestion" src={submit_question} alt="err" />
          </button>
        </div>
      ) : (
        <div className="result-container">
          {showImage ? (
            <>
              <img src={loading} alt="Loading" />
              <img className="text-result" src={scoretext} alt="Loading" />
            </>
          ) : (
            <>
              {showLastImg && (
                <div className="lastimg">
                  <img className="jethaimg" src={jethalal} alt="Jethalal" />
                  <img className="jethatext" src={jethatext} alt="Jethalal" />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizMcq;
